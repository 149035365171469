<template>
  <div class="price-options">
    <h2 class="light-primary-title text-align-center">
      {{ $t('choose_plan') }}
    </h2>

    <div class="price-options__price-blocks">
      <div class="price-block">
        <header class="price-block__header">
          <h3 class="price-block__name light-primary-title">
            {{ $t('price_options.standard_title') }}
          </h3>
          <h4 class="price-block__subtitle">
            {{ $t('price_options.standard_subtitle') }}
          </h4>
        </header>
        <div class="price-block__list" v-html="$t('price_options.standard_list')" />
        <!--        <span class="font-size-small">{{ $t('price_options.temp_offer') }}</span>-->
        <div class="price-block__price" v-html="$t('price_options.standard_price')" />
        <div class="price-block__action">
          <app-button @click="startOrder()">
            {{ $t('choose_and_start_sharing') }}
          </app-button>
        </div>
      </div>

      <div class="price-block price-block-invert">
        <div class="price-block__ribbon">
          {{ $t('most_chosen_usp') }}
        </div>

        <header class="price-block__header">
          <h3 class="price-block__name light-primary-title-invert">
            {{ $t('price_options.hosting_title') }}
          </h3>
          <h4 class="price-block__subtitle">
            {{ $t('price_options.hosting_subtitle') }}
          </h4>
        </header>
        <div class="price-block__list" v-html="$t('price_options.hosting_list')" />
        <!--        <span class="font-size-small">{{ $t('price_options.temp_offer') }}</span>-->
        <div class="price-block__price" v-html="$t('price_options.hosting_price')" />
        <div class="price-block__action">
          <app-button
            light
            class="price-block__action-button"
            @click="startOrder('?extra-option=2')"
          >
            {{ $t('choose_and_start_sharing') }}
          </app-button>
        </div>
      </div>

      <div class="price-block">
        <header class="price-block__header">
          <h3 class="price-block__name light-primary-title">
            {{ $t('price_options.video_title') }}
          </h3>
          <h4 class="price-block__subtitle">
            {{ $t('price_options.video_subtitle') }}
          </h4>
        </header>
        <div class="price-block__list" v-html="$t('price_options.video_list')" />
        <div class="price-block__price" v-html="$t('price_options.video_price')" />
        <div class="price-block__action">
          <app-button @click="startOrder('?extra-option=2,3')">
            {{ $t('choose_and_start_sharing') }}
          </app-button>
        </div>
      </div>
    </div>

    <div class="price-options__info">
      <p>* {{ $t('price_options.google_drive_constrains') }}</p>
      <p>** {{ $t('price_options.storage_constrains') }}</p>
      <p>** {{ $t('price_options.hosting_constrains') }}</p>
      <p>*** {{ $t('cancel_usp_description') }}</p>
    </div>
  </div>
</template>

<script setup>
  import { useLinks } from '~/lib/composables/links'

  const { startOrder } = useLinks()
</script>

<style lang="scss">
  .price-block__ribbon {
    font-size: 18px;
    font-weight: var(--font-weight-regular);
    color: var(--color-primary);
    position: absolute;
    top: -2px;
    right: 12px;
    --r: .8em; /* control the cutout */

    border-inline: .5em solid #0000;
    padding: .5em 12px calc(var(--r) + .2em);
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100% - .5em) calc(100% - var(--r)), 50% 100%, .5em calc(100% - var(--r)), 0 100%);
    background: var(--color-secondary) padding-box;
    width: fit-content;
  }

  .price-options {
    display: flex;
    flex-direction: column;
  }

  .price-options__price-blocks {
    display: flex;
    gap: var(--spacing-medium);
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }
  }

  .price-block__subtitle {
    font-weight: normal;
    margin-bottom: var(--spacing-default);
  }

  .price-block__name {
    font-size: 2rem
  }

  .price-options__info {
    font-size: .8rem;
    margin-top: var(--spacing-large);
  }

  .price-block {
    position: relative;
    padding: var(--spacing-large);
    box-shadow: var(--box-shadow);
    border-radius: var(--default-border-radius);
    width: 100%;
    background: var(--color-lightest);
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  .price-block-invert {
    color: var(--color-lightest);
    background: var(--color-primary);

    @include breakpoint(sm) {
      transform: scale(1.05);
    }
  }

  .price-block__list {
    margin-bottom: var(--spacing-medium);
    flex-grow: 1;
  }

  .price-block__list-item-new {
    background: var(--color-secondary);
    color: black;
    padding: 1px 5px;
    border-radius: 4px;
    margin: 0 4px 0 0;
    font-weight: 400;
  }

  .price-block__price {
    margin-bottom: var(--spacing-medium);
    font-size: 1.6rem;
    font-weight: 200;
  }
</style>
